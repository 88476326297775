$lightBlue: #00AAED;
$lightGreen: #00D4A6;
$LineBlue: #16AAED;
$deepOcean: #1A6887;
$lightPink: #F8A48D;
$pink: #F77B74;
$darkBlue: #0B2D3B;
$doveGray: #707070;
$wild-sand: #F5F5F5;
$white: #fff;
$gradient: linear-gradient(#1A6887, #0D3444);
