header {
  .preheader {
    display: none;

    @media screen and (min-width: 992px) {
      display: flex;
    }

    background: $gradient;
    height: 50px;
    align-items: center;

    &__container {
      display: flex;
      width: 100%;
      align-items: center;
      justify-content: center;

      &--menu {
        // display: flex;
        // align-items: center;
        width: 100%;
        color: $pink;
        font-weight: 700;
        font-family: $ubuntu;

        a {
          font-weight: 500;
          color: $white;

          img {
            width: 20px;
            margin-right: .2rem;
          }
        }

        &:last-child {
          text-align: right;
        }
      }
    }
  }

  .navbar {
    position: absolute;
    width: 100%;
    padding: .5rem 0;

    @media screen and (min-width: 992px) {
      position: relative;
    }


  }

  nav#menu-desk-header {
    li {
      display: block;
      padding: .5rem .75rem;

      a {
        color: lighten($deepOcean, 13);
        font-family: $ubuntu;
        font-weight: lighter;

        &:hover,
        &:focus {
          color: lighten($deepOcean, 5);
        }
      }
    }
  }

  #desktop-sidebar-header {
    .desktop-sidebar-header {
      &__sidebar {
        display: block;
        position: fixed;
        top: 0;
        box-shadow: 5px 5px 5px 5px #000000cc;
        padding: 1.5rem 2rem;
        min-height: 100vh;
        width: 25vw;
        // background: transparentize($deepOcean, 0.1);
        background: linear-gradient(#1a6887e0, #0d3444eb);
        z-index: 999;
        color: white;
        transition: 300ms cubic-bezier(0.455, 0.03, 0.515, 0.955);
        right: calc(-45vw - 5px);

        &.open {
          right: 0;
        }

        &--close-button {
          display: flex;
          width: 20px;
          height: 20px;
          margin: 0.5rem 0rem 0.5rem auto;
          margin-bottom: 3rem;
        }

        li {
          display: block;
          // padding: .5rem 0;
          padding: 1rem 0;

          &.menu-item-has-children {
            position: relative;

            &:after {
              content: '';
              position: absolute;
              width: 100%;
              height: 1px;
              background: white;
              bottom: 0;
              left: 0;
              opacity: .3;
            }

            &:before {
              content: '';
              position: absolute;
              width: 20px;
              height: 15px;
              background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyNS4zNzUiIGhlaWdodD0iMTMuOTciIHZpZXdCb3g9IjAgMCAyNS4zNzUgMTMuOTciPg0KICA8cGF0aCBpZD0iZG93bi1jaGV2cm9uIiBkPSJNMjQuMDU2LDkxLjU2NywxMi42ODgsMTAyLjksMS4zMTksOTEuNTY3LDAsOTIuODlsMTIuNjg4LDEyLjY0NkwyNS4zNzUsOTIuODlaIiB0cmFuc2Zvcm09InRyYW5zbGF0ZSgwIC05MS41NjcpIiBmaWxsPSIjZmZmIi8+DQo8L3N2Zz4NCg==");
              // background-image: url('/dist/assets/img/header/down-chevron.svg');
              background-repeat: no-repeat;
              background-size: contain;
              right: 10px;
              top: 15px;
              transition: all .3s ease-in-out;
            }

            .sub-menu {
              margin-bottom: 0;

              li {
                padding: .5rem 0;
              }
            }
          }

          a {
            color: white;
            font-family: $ubuntu;
            font-weight: lighter;
          }
        }
      }
    }
  }

  // menu empreendimentos
  .desktop-sidebar-empreendimentos {
    position: fixed;
    width: 100%;
    right: 0;
    top: 0;
    height: 100vh;
    z-index: 999;
    // transform: translateX(100%);
    right: -100%;
    will-change: transform;
    transition: all .8s ease-in-out;
    display: none;

    &.open {
      right: 0;
      display: block;
      // transform: translateX(0%);
    }

    &__menu {
      height: 100vh;
      background: linear-gradient(#1a6887e0, #0d3444eb);
      // background: transparentize($deepOcean, 0.1);

      &--close-button {
        text-align: end;
        padding: 2rem 3rem;
      }

      form {
        padding: 5rem;
      }

      &--links {
        position: relative;
        height: 50px;
        width: 100%;

        input,
        label {
          display: block;
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
        }

        input[type="radio"] {
          opacity: 0.011;
          z-index: 100;
          width: 100%;
          height: 40px;
          cursor: pointer;
        }

        label {
          padding: 5px;
          cursor: pointer;
          z-index: 90;
          border-bottom: 1px solid #CCC;
          font-size: 22px;
          font-family: $ubuntu;
          color: #fff;
          font-weight: 100;
        }

        input[type="radio"]:checked+label {
          font-weight: 700;
        }
      }
    }

    &__thumbs {
      background: $wild-sand;
      height: 100vh;
      background-image: url(../assets/img/forma.svg);
      background-size: cover;
      padding: 2rem;
      will-change: transform;
      transform: translateX(100%);
      transition: all .8s ease-in-out;

      &.open {
        transform: translateX(0%);
      }

      &--category {
        height: 100px;

        &-title,
        &-description {
          width: 100%;
          font-family: $ubuntu;
        }

        &-title {
          color: $deepOcean;
          font-weight: 700;
        }

        &-description {
          color: $doveGray;
          max-width: 600px;
        }
      }

      &--content {
        &-card {
          background: #fff;
          box-shadow: -3px 3px 3px 3px rgb(0 0 0 / 10%);
          border-radius: 5px;
          margin-bottom: 1rem;

          img {
            width: 100%;
            object-fit: cover;
            height: 120px;
          }

          &-flag {
            position: absolute;
            top: 0;
            right: 20px;
            width: 70px;

            &__content {
              height: 70px;
              width: 100%;
              border-left: 35px solid $pink;
              border-right: 35px solid $pink;
              border-bottom: 15px solid transparent;
              position: relative;

              p {
                position: absolute;
                width: 70px;
                left: -35px;
                top: 15%;
                text-align: center;
                display: flex;
                justify-content: center;
                color: $white;
                font-size: 14px;
                font-weight: 500;
                font-family: $ubuntu;
              }
            }
          }

          &-text {
            padding: .5rem 1rem;
            font-family: $ubuntu;

            h5 {
              font-size: 1.2rem;
              color: $deepOcean;
              font-weight: 500;
            }

            span {
              color: $lightPink;
            }
          }

          &-button {
            font-family: $ubuntu;
            width: fit-content;
            margin: 0 auto;
            padding-bottom: 1rem;

            a {
              background-color: $lightBlue;
              transition: 250ms ease-in;
              padding: .5rem 1.1rem;
              border-radius: 25px;
              border: none;
              color: #fff;

              &:hover,
              &:focus {
                color: #fff;
                background-color: darken($lightBlue, 7);
              }
            }
          }
        }
      }
    }
  }

  .desktop-sidebar-contact {

    position: fixed;
    top: 0;
    right: calc(-45vw - 5px);
    box-shadow: 5px 5px 5px 5px #000000cc;
    padding: 0rem 2rem;
    min-height: 100vh;
    width: 45vw;
    // background: transparentize($deepOcean, 0.1);
    background: linear-gradient(#1a6887e0, #0d3444eb);
    z-index: 999;
    color: white;

    transition: 300ms cubic-bezier(0.455, 0.03, 0.515, 0.955);

    display: none;

    &.open {
      right: 0;
      display: block;
      // transform: translateX(0%);
    }

    &--close-button {
      display: flex;
      width: 20px;
      height: 20px;
      margin: 0.5rem 0rem 0.5rem auto;
    }

    &__form {
      .form-title {
        color: $pink;
        font-family: $ubuntu;
        font-weight: bold;
        margin-bottom: 1.5rem;
      }

      .form-description {
        font-family: $ubuntu;
        font-weight: 300;
        color: white;
        margin-bottom: 1.5rem;

        p {
          margin-bottom: 0;
        }
      }

      form {
        // padding: 2rem 0rem;

        label {
          text-transform: uppercase;
          font-size: 14px;
          color: $white;
        }

        select {
          background: transparent;
          border-bottom: 2px solid $white;
          color: #b5b5b5;
          font-family: $ubuntu;

          option {
            color: $doveGray;
            background-color: transparent;
          }
        }

        .select-label {
          position: relative;
          padding-top: 15px;
          margin-bottom: .75rem;

          .floating-select {
            padding: 4px 4px;
            display: block;
            width: 100%;
            background-color: transparent;
            border: none;
            border-bottom: 2px solid $white;

            &.invalid {
              border-bottom: 2px solid red;
            }
          }

          .floating-select:focus {
            outline: none;
            border-bottom: 2px solid $white;
          }

          label {
            color: $white;
            font-size: 14px;
            position: absolute;
            pointer-events: none;
            top: 5px;
            transition: 0.3s ease all;
            -moz-transition: 0.3s ease all;
            -webkit-transition: 0.2s ease all;
          }

          .floating-select:focus~label,
          .floating-select:not([value=""]):valid~label {
            top: -5px;
            font-size: 13px;
            color: $white;
          }

          .floating-select.invalid~label {
            color: red;
          }

          /* active state */
          .floating-select:focus~.floating-select:focus~ {
            width: 50%;
          }

          /* active state */
          .floating-select:focus~ {
            -webkit-animation: inputHighlighter 0.3s ease;
            -moz-animation: inputHighlighter 0.3s ease;
            animation: inputHighlighter 0.3s ease;
          }
        }

        .label-float {
          position: relative;
          padding-top: 15px;
          margin-bottom: .75rem;

          input,
          textarea {
            border: 0;
            border-bottom: 2px solid $white;
            outline: none;
            width: 100%;
            transition: all .3s ease-out;
            -webkit-transition: all .3s ease-out;
            -moz-transition: all .3s ease-out;
            -webkit-appearance: none;
            border-radius: 0;
            font-family: $ubuntu;
            color: #b5b5b5;
            background: transparent;

            &:focus {
              border-bottom: 2px solid $white;
            }

            &::placeholder {
              color: transparent;
            }
          }

          label {
            pointer-events: none;
            position: absolute;
            top: -5px;
            left: 0;
            margin-top: 15px;
            transition: all .3s ease-out;
            -webkit-transition: all .3s ease-out;
            -moz-transition: all .3s ease-out;
            text-transform: uppercase;
            font-size: 14px;
            color: $white;
          }

          input.invalid+label,
          textarea.invalid+label {
            color: red;
          }

          input.invalid,
          textarea.invalid {
            border-bottom: 2px solid red;
          }

          input.invalid+label:before,
          textarea.invalid+label:before {
            content: '*';
          }

          input:focus+label,
          textarea:focus+label,
          textarea:not(:placeholder-shown)+label,
          input:not(:placeholder-shown)+label {
            font-size: 13px;
            margin-top: 0;
          }

          // &.slideup-email,
          // &.slideup-phone,
          // &.slideup-whatsapp{
          //   display: none;
          // }
        }

        input[type=checkbox] {
          width: 20px;
          height: 20px;
          // margin-top: 15px;
        }

        .legal {
          p {
            font-size: 12px;

            a {
              color: $white;
              text-decoration: underline;
            }

            // color: $white;
          }
        }

        .flex2 {
          display: flex;
          font-size: 12px;
          align-items: center;

          div {
            width: 85%;
            margin-left: 15px;

            input {
              width: 4%;
              margin-top: 0;
            }
          }
        }

        // .frm-contact-label {
        //   font-family: $ubuntu;
        //   text-transform: uppercase;
        //   font-size: .9rem;
        // }

        // .custom-form-contact {
        //   font-family: $ubuntu;
        //   background: transparent;
        //   color: #e4e4e4;
        //   border: none;
        //   border-bottom: 1px solid white;
        //   border-radius: 0;

        //   transition: all 200ms ease;

        //   &:focus {
        //     border-bottom-color: #aaa;
        //   }
        // }

        .btn-custom {
          color: darken($lightBlue, 7);
          background-color: white;
          transition: 250ms ease-in;
          padding: .5rem 1.1rem;
          border-radius: 25px;
          border: none;
          width: 150px;
          margin-top: .5rem;

          &:hover,
          &:focus {
            background-color: darken(white, 7);
          }
        }
      }

      .success-send {
        margin: 3rem auto;

        h3 {
          color: $pink;
          font-family: $ubuntu;
          font-weight: bold;
        }
      }
    }
  }

  #desktop-sidebar-empreendimentos {
    .desktop-sidebar-header {
      &__sidebar {
        position: fixed;
        top: 0;
        right: calc(-45vw - 5px);
        box-shadow: 5px 5px 5px 5px #000000cc;
        padding: 1.5rem 2rem;
        min-height: 100vh;
        width: 45vw;
        // background: transparentize($deepOcean, 0.1);
        background: linear-gradient(#1a6887e0, #0d3444eb);
        z-index: 999;
        color: white;
        display: none;

        transition: 300ms cubic-bezier(0.455, 0.03, 0.515, 0.955);

        &.open {
          right: 0;
          display: block;
        }

        &--close-button {
          display: flex;
          width: 20px;
          height: 20px;
          margin: 0.5rem 0rem 0.5rem auto;
        }

        li {
          display: block;
          padding: .5rem 0;

          a {
            color: white;
            font-family: $ubuntu;
            font-weight: lighter;
          }
        }
      }
    }
  }

  // EMPREENDIMENTOS

  #mobile-menu-header {

    .mobile-menu-header {
      &__sidebar {
        display: block;

        position: fixed;
        top: 0;
        right: calc(-70vw - 5px);
        box-shadow: 5px 5px 5px 5px #000000cc;
        padding: 1.5rem 2rem;
        min-height: 100vh;
        width: 70vw;
        // background: transparentize($deepOcean, 0.1);
        background: linear-gradient(#1a6887e0, #0d3444eb);
        z-index: 999;
        color: white;

        transition: 300ms cubic-bezier(0.455, 0.03, 0.515, 0.955);

        &.open {
          right: 0;
        }

        &--close-button {
          display: flex;
          width: 20px;
          height: 20px;
          margin: 0.5rem 0rem 0.5rem auto;
        }

        li {
          display: block;
          padding: .5rem 0;

          a {
            color: white;
          }
        }
      }
    }
  }
}
