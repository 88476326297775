@import '../fonts';
@import '../colors';


.contato {
  padding-top: 6rem;
  padding-bottom: 5rem;
  background: #F5F5F5;

  // background-image: url('../img/faq/forma.svg');
  &__title {
    color: $deepOcean;
    font-weight: bold;
    font-family: $ubuntu;
    text-align: center;
  }

  &__description {
    color: #707070;
    font-family: $ubuntu;
    text-align: center;
  }

  &__formulario {
    background: linear-gradient(#1A6887, #0D3444);
    padding: 15px;
    margin-top: 2rem;

    @media screen and (min-width: 768px) {
      padding: 35px;
    }

    &--title {
      color: white;
      margin-bottom: 45px;
      font-size: 23px;
      font-family: $ubuntu;
      font-weight: 100;

      @media screen and(min-width: 992px) {
        font-size: 24px;
      }
    }


    form {
      input[type=checkbox] {
        width: 20px;
        height: 20px;
        margin-bottom: 0;
        // margin-top: 15px;
      }

      .legal {
        p {
          font-size: 12px;
          color: $white;

          a {
            color: $white;
            text-decoration: underline;
          }

          color: $white;
        }
      }

      .flex2 {
        display: flex;
        font-size: 12px;
        align-items: center;
        color: $white;

        div {
          width: 85%;
          margin-left: 15px;

          input {
            width: 4%;
            margin-top: 0;
          }
        }
      }

      label {
        text-transform: uppercase;
        font-size: 14px;
        color: white;

        @media screen and(min-width: 992px) {
          font-size: 15px;
        }
      }

      input {
        border: none;
        border-bottom: 1px solid white;
        width: 97%;
        margin-bottom: 16px;
        background: transparent;
      }


      button {
        display: block;
        margin: 0 auto;
        color: white;
        background-color: #FF8A8A;
        border: none;
        padding: 8px;
        margin-top: 15px;
        width: 170px;
        border-radius: 24px;
        -webkit-border-radius: 24px;
        -moz-border-radius: 24px;
        -ms-border-radius: 24px;
        -o-border-radius: 24px;

        transition: 200ms ease-out;

        &:hover,
        &:focus {
          filter: brightness(107%);
        }
      }

      label {
        text-transform: uppercase;
        font-size: 14px;
        color: $white;
      }

      select {
        background: transparent;
        border-bottom: 2px solid $white;
        color: #b5b5b5;
        font-family: $ubuntu;

        option {
          color: $doveGray;
          background-color: transparent;
        }
      }

      .select-label {
        position: relative;
        padding-top: 15px;
        margin-bottom: .75rem;

        .floating-select {
          padding: 4px 4px;
          display: block;
          width: 100%;
          background-color: transparent;
          border: none;
          border-bottom: 2px solid $white;

          &.invalid {
            border-bottom: 2px solid red;
          }
        }

        .floating-select:focus {
          outline: none;
          border-bottom: 2px solid $white;
        }

        label {
          color: $white;
          font-size: 14px;
          position: absolute;
          pointer-events: none;
          top: 5px;
          transition: 0.3s ease all;
          -moz-transition: 0.3s ease all;
          -webkit-transition: 0.2s ease all;
        }

        .floating-select:focus~label,
        .floating-select:not([value=""]):valid~label {
          top: -5px;
          font-size: 13px;
          color: $white;
        }

        .floating-select.invalid~label {
          color: red;
        }

        /* active state */
        .floating-select:focus~.floating-select:focus~ {
          width: 50%;
        }

        /* active state */
        .floating-select:focus~ {
          -webkit-animation: inputHighlighter 0.3s ease;
          -moz-animation: inputHighlighter 0.3s ease;
          animation: inputHighlighter 0.3s ease;
        }
      }

      .label-float {
        position: relative;
        padding-top: 15px;
        margin-bottom: .75rem;

        input,
        textarea {
          border: 0;
          border-bottom: 2px solid $white;
          outline: none;
          width: 100%;
          transition: all .3s ease-out;
          -webkit-transition: all .3s ease-out;
          -moz-transition: all .3s ease-out;
          -webkit-appearance: none;
          border-radius: 0;
          font-family: $ubuntu;
          color: #b5b5b5;
          background: transparent;

          &:focus {
            border-bottom: 2px solid $white;
          }

          &::placeholder {
            color: transparent;
          }
        }

        label {
          pointer-events: none;
          position: absolute;
          top: -5px;
          left: 0;
          margin-top: 15px;
          transition: all .3s ease-out;
          -webkit-transition: all .3s ease-out;
          -moz-transition: all .3s ease-out;
          text-transform: uppercase;
          font-size: 14px;
          color: $white;
        }

        input.invalid+label,
        textarea.invalid+label {
          color: red;
        }

        input.invalid,
        textarea.invalid {
          border-bottom: 2px solid red;
        }

        input.invalid+label:before,
        textarea.invalid+label:before {
          content: '*';
        }

        input:focus+label,
        textarea:focus+label,
        textarea:not(:placeholder-shown)+label,
        input:not(:placeholder-shown)+label {
          font-size: 13px;
          margin-top: 0;
        }

        // &.slideup-email,
        // &.slideup-phone,
        // &.slideup-whatsapp{
        //   display: none;
        // }
      }
    }
  }
}
