.menu{

  &__fixed {
    display: flex;
    justify-content: space-around;
    align-items: baseline;
    color: #16AAED;
    position: fixed;
    bottom: 0;
    background: white;
    width: 100%;
    z-index: 999;
    font-weight: lighter;
    font-family: $ubuntu;
    // z-index: 2;
    height: 70px;
    overflow-x: hidden;
    overflow-y: hidden;
    box-shadow: 0px -5px 5px 0px rgba(0,0,0,0.15);
	padding-bottom: 140px;

    a{
      color: #16AAED;
      &:hover,
      &:visited{
        color: #16AAED;
      }
    }
    >div {
      padding: .5rem;
    }

    @media screen and(min-width: 992px) {
      display: none;
    }

    img {
      display: block;
      margin: 5px auto;
    }

    .mais {
      margin-bottom: 9px;
    }
  }

  &__sidebar{
    position: fixed;
    width: 100%;
    right: 0;
    top: 0;
    height: 100%;
    z-index: 2;
    display: flex;
    align-items: center;
    background: $gradient;
    transform: translateX(100%);
    transition: all .3s ease-in-out;
    padding: 1rem;
    opacity: .95;
    &.open{
      transform: translateX(0);
    }

    &--close{
      position: absolute;
      top: 20px;
      right: 20px;
    }

    &--menu{
      width: 100%;
      .menu-menu-mais-container,
      .menu-menu-empreendimentos-container{
        list-style: none;

        li{
          padding: 1rem 0;
          position: relative;
          &:after{
            content: '';
            position: absolute;
            width: 100%;
            height: 1px;
            background: white;
            bottom: 0;
            left: 0;
            opacity: .3;
          }
          &.menu-item-has-children{
            position: relative;

            &:before{
              content: '';
              position: absolute;
              width: 20px;
              height: 15px;
              background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyNS4zNzUiIGhlaWdodD0iMTMuOTciIHZpZXdCb3g9IjAgMCAyNS4zNzUgMTMuOTciPg0KICA8cGF0aCBpZD0iZG93bi1jaGV2cm9uIiBkPSJNMjQuMDU2LDkxLjU2NywxMi42ODgsMTAyLjksMS4zMTksOTEuNTY3LDAsOTIuODlsMTIuNjg4LDEyLjY0NkwyNS4zNzUsOTIuODlaIiB0cmFuc2Zvcm09InRyYW5zbGF0ZSgwIC05MS41NjcpIiBmaWxsPSIjZmZmIi8+DQo8L3N2Zz4NCg==");
              // background-image: url('/dist/assets/img/header/down-chevron.svg');
              background-repeat: no-repeat;
              background-size: contain;
              right: 10px;
              top: 25px;
              transition: all .3s ease-in-out;
            }
          }
          &.active{
            &:before{
              transform: rotate(180deg);
            }
          }
          a{
            color: white;
            font-family: $ubuntu;
            font-weight: 100;
            &:hover{
              text-decoration: none;
            }
          }

          .sub-menu{
            margin-bottom: 0;

            li{
              list-style: none;
              padding-bottom: 0;
              &:after{
                display: none;
              }
            }
          }
        }
      }
    }
  }
}
