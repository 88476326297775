@import './colors';
@import './fonts';

@import "./header.scss";
@import "./footer.scss";

@import "./menu-fixed";

// @import "./single/lancamentos.scss";

// @import "./pages/faq.scss";
// @import "./pages/transparencia.scss";
// @import "./pages/politica-privacidade.scss";
// @import "./pages/contato.scss";

.postsPagination {
  text-align: center;
  margin-bottom: 2rem;
  a, span{
    margin: 0 0.25rem;
    background: $deepOcean;
    width: 35px;
    height: 35px;
    padding: 0.5rem 0.75rem;
    border-radius: 50%;
    text-decoration: none;
    color: #fff;
  }
  .current{
    background: $pink;
    color: #fff;
  }
}
