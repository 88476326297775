@import 'fonts';

.footer {
  background: url(../assets/img/forma.svg), linear-gradient(#1A6887, #0D3444);
  // background: url(../assets/img/footer/footer-background@2x.png), linear-gradient(#1A6887, #0D3444);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  height: auto;
  color: white;
  padding: 20px 0px 50px 0px;

  @media(min-width: 768px) {
    padding: 20px 0px 0px 0px
  }

  a {
    color: white;
    text-decoration: none;
  }

  &__menu {

    &--content {
      @media(min-width: 992px) {
        padding: 12px;
        display: flex;
        justify-content: space-evenly;
      }

      &-logo {
        display: block;
        margin: 0px auto;

        @media(min-width: 992px) {
          margin: auto 0px;
          padding: 25px 0px;
        }
      }

      &-mobile {
        line-height: 2;
        font-size: 20px;
        font-family: "Ubuntu";
        margin: 15px 0px;

        ul {
          padding: 0;
          list-style-type: none;
        }

        @media(min-width: 768px) {
          text-align: center;
          margin: 20px 0px
        }

        @media(min-width: 992px) {
          display: none;
        }
      }

      &-links-rapidos {
        display: none;

        ul {
          padding: 0;
          list-style-type: none;
        }

        @media(min-width: 992px) {
          display: block;
          line-height: 2;
          font-size: 19px;
          font-family: "Ubuntu";
          margin: 15px 0px;

          a {
            &:hover {
              text-decoration: underline;
            }
          }

          li:first-child {
            text-transform: uppercase;
            font-weight: bold;
            pointer-events: none;
            cursor: default;
          }
        }
      }

      &-privacidade {
        display: none;

        ul {
          padding: 0;
          list-style-type: none;
        }

        @media(min-width: 992px) {
          display: block;
          line-height: 2;
          font-size: 19px;
          font-family: "Ubuntu";
          margin: 15px 0px;

          a {
            &:hover {
              text-decoration: underline;
            }
          }

          li:first-child {
            text-transform: uppercase;
            font-weight: bold;
            pointer-events: none;
            cursor: default;
          }
        }
      }

      .acompanheRedes {
        @media(min-width: 768px) {
          display: none;
        }
      }

      &-redes-sociais {
        display: flex;
        justify-content: center;
        flex-direction: column;
        padding-bottom: 2rem;

        .social {
          display: none;

          @media(min-width: 992px) {
            font-family: $ubuntu;
            display: block;
            margin-top: 23px;

            h5 {
              text-transform: uppercase;
              font-weight: bold;
              pointer-events: none;
              cursor: default;
            }
          }
        }

        ul {
          display: inline;
          padding: 0;

          li {
            display: inline-block;
            margin-right: 1rem;
          }
        }

        &-icone {
          width: 30px;
          height: 30px;
          object-fit: contain;
        }

        &-selo {
          // width: 100px;
          height: 80px;
          object-fit: contain;
        }
      }
    }
  }

  .copyright {
    display: none;

    @media(min-width: 992px) {
      display: flex;
      justify-content: center;
      color: white;
    }
  }
}


.modal-popup {
  .modal-content {
    background-color: transparent;
    box-shadow: none;
    border: none;
  }

  .modal-footer {
    border: none;
    margin: 0 auto;

    button {
      background-color: transparent;
      border: 2px solid white;
      font-weight: 600;
      color: white;
    }
  }
}
